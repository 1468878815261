.App {
  /* text-align: center; */
  /* background-color: #282c34; */
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  height: fit-content;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 200px;
  width: 220px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container {
  display: flex;
  justify-content: center;
}

.fullscreen-container {
  padding-top: 10px;
  width: 65%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.unity-loader {
  height: 70%;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4rem solid #31302f;
  box-shadow: 5px 10px 5px #181a1f;
}

.unity-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-button {
  width: 45%;
  cursor: pointer;
}
.fullscreen-button-container {
  display: flex;
  justify-content: flex-end;
}

.controls-button {
  width: 45%;
  cursor: pointer;
}

.controls-button-hold {
  width: 45%;
  cursor: pointer;
  opacity: 0.5;
}

.controls-button-container {
  display: flex;
  justify-content: flex-start;
}

.keys-container {
  display: flex;
  justify-content: center;
}

.keys {
  width: 315px;
}

.underfooter-container {
  width: 65%;
  display: flex;
  justify-content: center;
  margin-top: -75px;
  margin-left: -50px;
}

.leaderboard-container {
  padding-top: 150px;
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
}

.pogo-back-img {
  height: 1100px;
  width: auto;
  opacity: 0.2;
  position: absolute;
  top: 1000px;
  right: 0px;
  z-index: -1;
  overflow: hidden;
}

.leaderboard-title {
  width: 400px;
}

.name-title {
  width: 80px;
  padding: 5px;
}
.time-title {
  width: 80px;
  padding: 5px;
}
.rank-title {
  width: 80px;
  padding: 5px;
}

.leaderboard {
  padding-top: 250px;
}

.leaderboard-border {
  border: 0.4rem solid #31302f;
  box-shadow: 5px 10px 5px #181a1f;
  margin-right: 20%;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
}

.rank-one {
  background: #ffea96 !important;
}

.rank-two {
  background: #d1dae8 !important;
}

.rank-three {
  background: #eeb379 !important;
}

.modal-container {
  display: flex;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
