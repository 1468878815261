@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* text-align: center; */
  /* background-color: #282c34; */
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 200px;
  width: 220px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container {
  display: flex;
  justify-content: center;
}

.fullscreen-container {
  padding-top: 10px;
  width: 65%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.unity-loader {
  height: 70%;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4rem solid #31302f;
  box-shadow: 5px 10px 5px #181a1f;
}

.unity-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-button {
  width: 45%;
  cursor: pointer;
}
.fullscreen-button-container {
  display: flex;
  justify-content: flex-end;
}

.controls-button {
  width: 45%;
  cursor: pointer;
}

.controls-button-hold {
  width: 45%;
  cursor: pointer;
  opacity: 0.5;
}

.controls-button-container {
  display: flex;
  justify-content: flex-start;
}

.keys-container {
  display: flex;
  justify-content: center;
}

.keys {
  width: 315px;
}

.underfooter-container {
  width: 65%;
  display: flex;
  justify-content: center;
  margin-top: -75px;
  margin-left: -50px;
}

.leaderboard-container {
  padding-top: 150px;
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
}

.pogo-back-img {
  height: 1100px;
  width: auto;
  opacity: 0.2;
  position: absolute;
  top: 1000px;
  right: 0px;
  z-index: -1;
  overflow: hidden;
}

.leaderboard-title {
  width: 400px;
}

.name-title {
  width: 80px;
  padding: 5px;
}
.time-title {
  width: 80px;
  padding: 5px;
}
.rank-title {
  width: 80px;
  padding: 5px;
}

.leaderboard {
  padding-top: 250px;
}

.leaderboard-border {
  border: 0.4rem solid #31302f;
  box-shadow: 5px 10px 5px #181a1f;
  margin-right: 20%;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
}

.rank-one {
  background: #ffea96 !important;
}

.rank-two {
  background: #d1dae8 !important;
}

.rank-three {
  background: #eeb379 !important;
}

.modal-container {
  display: flex;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

.container {
  width: 550px;
  margin: 80px auto;
}

.leadheader {
  width: 100%;
  padding: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.colheader {
  background: #4f5154;

  margin-left: 0;
  text-align: center;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  color: black;
  padding-right: 5px;
  padding-left: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.col-rank {
  flex: 1 1;
  justify-content: center;
  display: flex;
}

.col-name {
  flex: 4 1;
  justify-content: center;
  display: flex;
}

.col-time {
  flex: 4 1;
  justify-content: center;
  display: flex;
}

.col-user-rank {
  flex: 1 1;
  justify-content: center;
  display: flex;
}

.col-user-name {
  flex: 4 1;
  justify-content: center;
  display: flex;
}

.col-user-time {
  flex: 4 1;
  justify-content: center;
  display: flex;
}

.colheader h4 {
  font-size: 18px;
}

.users h4 {
  font-size: 16px;
}

.colheader .alltime {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.colheader .recent {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.users {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: #fefefe;
  color: black;

  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.users .name a {
  padding-top: 15px;
  vertical-align: middle;
}

.users img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #00a8e8;
  margin-right: 10px;
}

.users .rank {
  padding-left: 6px;
}

.users:nth-of-type(odd) {
  background: #fefefe;
}

.users:nth-of-type(even) {
  background: #f0f0f0;
}

.users:last-child {
  border-radius: 0 0 5px 5px;
}

@media (max-width: 550px) {
  .users .name {
    padding-left: 5px;
  }

  .container {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .container {
    padding: 0;
  }

  /* .colheader div[class^='col-xs'] {
    padding: 0;
  } */

  .users {
    text-align: center;
  }

  .users img {
    display: block;
    float: none;
    margin: 0 auto;
    width: 35px;
    height: 35px;
  }

  .users .name {
    text-align: center;
  }

  .users .name a {
    display: block;
    padding-top: 4px;
  }
}

.modal {
  /* width: 500px; */
  background: #4f5154;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  -webkit-filter: blur(0);
          filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  border: 0.4rem solid #31302f;
  box-shadow: 5px 10px 5px #181a1f;
}
.modal.off {
  opacity: 0;
  visibility: hidden;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}
@supports (offset-rotation: 0deg) {
  offset-rotation: 0deg;
  offset-path: path('M 250,100 S -300,500 -700,-200');
  .modal.off {
    offset-distance: 100%;
  }
}
@media (prefers-reduced-motion) {
  .modal {
    offset-path: none;
  }
}
.modal h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
  cursor: pointer;
}
.modal .content {
  padding: 1rem;
}
.modal .actions {
  border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;
}
.modal .actions button {
  border: 0;
  background: #78f89f;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
}
#centered-toggle-button {
  position: absolute;
}

