@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  font-family: 'Montserrat', sans-serif;
}

.container {
  width: 550px;
  margin: 80px auto;
}

.leadheader {
  width: 100%;
  padding: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.colheader {
  background: #4f5154;

  margin-left: 0;
  text-align: center;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  color: black;
  padding-right: 5px;
  padding-left: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.col-rank {
  flex: 1;
  justify-content: center;
  display: flex;
}

.col-name {
  flex: 4;
  justify-content: center;
  display: flex;
}

.col-time {
  flex: 4;
  justify-content: center;
  display: flex;
}

.col-user-rank {
  flex: 1;
  justify-content: center;
  display: flex;
}

.col-user-name {
  flex: 4;
  justify-content: center;
  display: flex;
}

.col-user-time {
  flex: 4;
  justify-content: center;
  display: flex;
}

.colheader h4 {
  font-size: 18px;
}

.users h4 {
  font-size: 16px;
}

.colheader .alltime {
  user-select: none;
}

.colheader .recent {
  cursor: pointer;
  user-select: none;
}

.users {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: #fefefe;
  color: black;

  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  text-align: center;
}

.users .name a {
  padding-top: 15px;
  vertical-align: middle;
}

.users img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #00a8e8;
  margin-right: 10px;
}

.users .rank {
  padding-left: 6px;
}

.users:nth-of-type(odd) {
  background: #fefefe;
}

.users:nth-of-type(even) {
  background: #f0f0f0;
}

.users:last-child {
  border-radius: 0 0 5px 5px;
}

@media (max-width: 550px) {
  .users .name {
    padding-left: 5px;
  }

  .container {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .container {
    padding: 0;
  }

  /* .colheader div[class^='col-xs'] {
    padding: 0;
  } */

  .users {
    text-align: center;
  }

  .users img {
    display: block;
    float: none;
    margin: 0 auto;
    width: 35px;
    height: 35px;
  }

  .users .name {
    text-align: center;
  }

  .users .name a {
    display: block;
    padding-top: 4px;
  }
}
